* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.icon {
  width: 0.64rem;
  height: 0.64rem;
  fill: currentColor;
  overflow: hidden;
}

.myOrders {
  padding-top: 1.066667rem;
  font-size: 0.32rem;
  text-align: center;
  color: #6492d7 !important;
}

.arrow {
  color: #6492d7;
  width: 0.373333rem;
  height: 0.373333rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

#root {
  min-height: 100vh;
}

.scrollView {
  width: 100vw;
  flex: 1 1;
  overflow: scroll;
}

/* alias */

.flex,
.button {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.jc,
.button {
  justify-content: center;
}

.ac,
.button {
  align-items: center;
}

.jcBetween {
  justify-content: space-between;
}

.jcAround {
  justify-content: space-around;
}

.flex1 {
  flex: 1 1;
}

.br10 {
  border-radius: 0.266667rem;
  overflow: hidden;
}

.br4 {
  border-radius: 0.106667rem;
  overflow: hidden;
}

.mt20 {
  margin-bottom: 0.533333rem;
}

.button {
  width: 3.2rem;
  height: 1.066667rem;
  border-radius: 0.533333rem;
  font-size: 0.426667rem;
  color: #ffffff;
  background: #eb624f;
  box-shadow: 0 0.053333rem 0.213333rem rgba(235, 98, 79, 0.4);
}

.am-checkbox-agree {
  margin-left: 0 !important;
}

.am-list-item .am-input-label,
.am-list-item .am-list-line .am-list-content,
.am-list-item .am-list-line .am-list-extra,
.am-list-item .am-input-control input,
.am-list-item .am-input-extra {
  font-size: 0.373333rem !important;
}

.am-list-body::before,
.am-list-body::after {
  height: 0 !important;
}

.am-button-primary,
.am-button {
  border-radius: 0.8rem !important;
}

.am-list-item .am-input-extra {
  color: #6492d7 !important;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-button::before {
    border: 0 !important;
  }
}

.am-search-cancel {
  display: none;
}